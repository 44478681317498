import React from "react";
import { Component } from "react";

class Greet extends Component{
    render(){
        return (
           <h1 class="centered">Website is in progress</h1>
        );
    }
}

export default Greet;