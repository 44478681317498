import Greet from './component/Greet';

function App() {
  return (
    <div className="App">
         <Greet></Greet>
    </div>
  );
}

export default App;
